import styled from "styled-components";

export const StyledFooterWrapper = styled.div`
  position: ${(props) =>
    props.location === "/" || props.location === "/returns"
      ? "relative"
      : "fixed"};
  bottom: 0;
  width: 100%;
  background: ${(props) =>
    props.location === "/" || props.location === "/returns"
      ? "transparent"
      : "#fff"};
  text-align: center;
  padding: 5px 10px;
  box-shadow: ${(props) =>
    props.location === "/" || props.location === "/returns"
      ? "none"
      : "4px 4px 0px rgba(0, 0, 0, 0.12)"};
  font-size: 12px;
  z-index: 7;
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;

  div {
    width: 90px;
    margin: 0px;
    padding-left: 10px;
    margin-top: 2px;
  }

  @media screen and (min-width: 768px) {
    background: ${(props) =>
      props.location === "/products" ? "#fff" : "transparent"};
    justify-content: ${(props) =>
      props.location === "/" || props.location === "/returns"
        ? "center"
        : "flex-end"};
    bottom: ${(props) => (props.location === "/products" ? "0px" : "10px")};
    box-shadow: none;

    div {
      margin: 1px 0px;
    }
  }
`;
