import React, { Fragment, useEffect, useState } from "react";
import { PickupAddress } from "components/Orders/Address";
import Header from "components/common/Header";
import { StyledExchange } from "components/ReturnCart/styled";
import { getMerchantConfig, getRmsConfig } from "features/config/configSlice";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  paymentStatus,
  selectedReturnOrderAPI,
  updateShippingDetails,
} from "utils/api";
import { getReturnOrder } from "features/orders/orderSlice";
import {
  Button,
  Drawer,
  Grid,
  Skeleton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from "@mui/material";
import styled from "styled-components";
import CartProducts from "components/common/CartProducts/CartProducts";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { format } from "date-fns";
import { PriceBox } from "components/common/PriceBox";
import Comment from "./Comment";
import { StyledApp, StyledComments, StyledExchangeDetails } from "./styled";
import { ReactComponent as CloseIcon } from "assets/close.svg";
import ModalImage from "react-modal-image";
import { getEmail, getPhone } from "features/user/userSlice";
import { ReactComponent as ErrorIcon } from "assets/error.svg";

function Tracking() {
  const location = useLocation();
  const merchantConfig = useSelector(getMerchantConfig);
  const returnOrderId = useSelector(getReturnOrder);
  const [returnItems, setReturnItems] = useState({});
  const [shippingDetails, setShippingDetails] = useState(false);
  const [uploads, setUploads] = useState(null);
  const [preview, setPreview] = useState(null);
  const [awbNumber, setAwbNumber] = useState(null);
  const [courierName, setCourierName] = useState(null);
  const [trackingLink, setTrackingLink] = useState(null);
  const [file, setFile] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const rmsConfig = useSelector(getRmsConfig);
  const [activeStep, setActiveStep] = useState(0);
  const [returnItemsLoader, setReturnItemsLoader] = useState(false);
  const navigate = useNavigate();
  const userPhone = useSelector(getPhone);
  const userEmail = useSelector(getEmail);
  const [blurTime, setBlurTime] = useState(0);
  const [apiErrorLoading, setApiErrorLoading] = useState(false);
  const [params, setParams] = useSearchParams();
  const encipherencode =
    sessionStorage.getItem("encipherencode") || params.get("encipherencode");
  const reference_id = params.get("rid");
  const transaction_id =
    params.get("razorpay_invoice_id") || params.get("txn_id");
  const order_id = params.get("order_id");
  const create_request = params.get("cr") || true;

  const returnRequestOrderAPI = (merchantId, returnid) => {
    selectedReturnOrderAPI(merchantId, returnid, reference_id, false)
      .then((resp) => {
        setReturnItems(resp.data);
        if (
          resp.data.status === 100 ||
          resp.data.status === 101 ||
          resp.data.status === 102 ||
          resp.data.status === 700
        ) {
          setActiveStep(0);
        } else if (
          resp.data.status === 200 ||
          resp.data.status === 201 ||
          resp.data.status === 202 ||
          resp.data.status === 300 ||
          resp.data.status === 301 ||
          resp.data.status === 305 ||
          resp.data.status === 306
        ) {
          setActiveStep(1);
        } else if (
          resp.data.status === 302 ||
          resp.data.status === 303 ||
          resp.data.status === 304 ||
          resp.data.status === 400
        ) {
          setActiveStep(2);
        } else if (
          resp.data.status === 500 ||
          resp.data.status === 600 ||
          resp.data.status === 501 ||
          resp.data.status === 601
        ) {
          setActiveStep(3);
        }
        setReturnItemsLoader(false);
      })
      .catch((err) => {
        if (userPhone === "" && userEmail === "") {
          let url = "/";
          if (encipherencode !== null && encipherencode !== undefined) {
            url += `?encipherencode=${encipherencode}`;
          }
          navigate(url);
        } else {
          setApiErrorLoading(true);
        }
      });
  };

  useEffect(() => {
    setReturnItemsLoader(true);
    if (
      merchantConfig &&
      merchantConfig !== null &&
      Object.keys(merchantConfig).length > 0 &&
      merchantConfig.merchant_id !== null
    ) {
      if (transaction_id) {
        paymentStatus(
          merchantConfig.merchant_id,
          transaction_id,
          reference_id,
          order_id,
          false,
          create_request
        ).then((resp) => {
          returnRequestOrderAPI(merchantConfig.merchant_id, returnOrderId);
        });
      } else {
        if (returnOrderId && returnOrderId !== null) {
          returnRequestOrderAPI(merchantConfig.merchant_id, returnOrderId);
        }
      }
    }

    function callFocus() {
      if (document.hasFocus()) {
        setBlurTime(0);
      } else {
        setBlurTime(blurTime + 1);
      }
    }
    const interval = setInterval(() => callFocus(), 60000 * 5);
    return () => {
      clearInterval(interval);
    };
  }, [merchantConfig]);

  useEffect(() => {
    if (
      returnItems !== undefined &&
      returnItems.self_ship &&
      returnItems.self_ship !== null
    ) {
      if (returnItems.self_ship.awb_number !== null) {
        setDisabled(true);
        setPreview(
          `${rmsConfig.s3_url}${returnItems.self_ship.consignment_image}`
        );
      } else {
        setDisabled(false);
      }
      setCourierName(returnItems.self_ship.courier_company);
      setTrackingLink(returnItems.self_ship.tracking_link);
      setAwbNumber(returnItems.self_ship.awb_number);
    }
  }, [returnItems]);

  const updatedAddress = {
    addressLine1: returnItems.customer_address_line_1,
    addressLine2: returnItems.customer_address_line_2,
    pincode: returnItems.customer_address_pin,
    city: returnItems.customer_address_city,
    state: returnItems.customer_address_state,
  };

  const steps = [
    returnItems && Object.keys(returnItems).length > 0
      ? returnItems.status === 700
        ? "Request Rejected"
        : "Request Created"
      : "Request Created",
    "Processing your Request",
    "Pickup Completed",
    returnItems && Object.keys(returnItems).length > 0
      ? returnItems.status === 500
        ? "Refund Initiated"
        : returnItems.status === 501
        ? "Refund Completed"
        : returnItems.status === 600
        ? "Exchange Initiated"
        : returnItems.status === 601
        ? "Exchange Completed"
        : "Request Completed"
      : "Request Completed",
  ];

  const [vertical, setVertical] = useState(
    window.innerWidth < 600 ? true : false
  );

  window.addEventListener("resize", () => {
    if (window.innerWidth < 600) {
      setVertical(true);
    } else {
      setVertical(false);
    }
  });

  const ColorlibConnector = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: merchantConfig.primary_theme_color,
        border: !vertical
          ? `1px solid ${merchantConfig.primary_theme_color}`
          : "",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: merchantConfig.primary_theme_color,
        border: !vertical
          ? `1px solid ${merchantConfig.primary_theme_color}`
          : "",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      border: !vertical ? "1px dashed #5C5C5C" : "",
    },
  }));

  const returnDate =
    returnItems.created_at !== undefined &&
    returnItems.created_at !== null &&
    returnItems.created_at.split(".");

  const submitHandler = (e) => {
    e.preventDefault();
    updateShippingDetails(file).then((resp) => {
      // console.log(resp.data);
    });
    setDisabled(true);
    setShippingDetails(false);
  };

  const handleUploads = (e) => {
    setUploads(e.target.files[0]);
    setPreview(URL.createObjectURL(e.target.files[0]));
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("merchant_id", merchantConfig.merchant_id);
    formData.append("return_request_id", returnItems.id);
    formData.append("awb_number", awbNumber);
    formData.append("tracking_link", trackingLink);
    formData.append("courier_company", courierName);
    setFile(formData);
  };

  return (
    <StyledApp merchantConfig={merchantConfig}>
      <Header />

      {apiErrorLoading ? (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "80vh",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <ErrorIcon />
          <p
            style={{
              marginTop: "25px",
              color: "#1d8dcc",
              textDecoration: "underline",
              cursor: "pointer",
              fontSize: "20px",
            }}
            onClick={() => window.location.reload()}
          >
            Reload
          </p>
        </div>
      ) : returnItemsLoader ? (
        <div style={{ padding: "100px" }}>
          <Skeleton style={{ width: "100%" }} height={50} />
          <div style={{ display: "flex" }}>
            <Skeleton height={100} style={{ width: "47.5%" }} />
            <Skeleton
              height={100}
              style={{ marginLeft: "100px", width: "47.5%" }}
            />
          </div>
        </div>
      ) : (
        <div style={{ padding: "0px 5.5%" }}>
          <div
            style={{
              paddingTop: "120px",
            }}
          >
            <p className="order-id">
              Order{" "}
              {returnItems?.order_name && returnItems.order_name !== ""
                ? returnItems.order_name
                : `#${returnItems.order_number}`}
            </p>
            <p className="return-id">Request ID #{returnItems.reference_id}</p>
          </div>

          <p className="request-date">
            Requested on{" "}
            {returnDate[0] !== undefined &&
              format(new Date(returnDate[0]), "dd MMMM yyyy")}{" "}
          </p>

          <Stepper
            activeStep={activeStep}
            connector={<ColorlibConnector />}
            alternativeLabel={vertical ? false : true}
            style={{ margin: "40px 0px" }}
            orientation={vertical ? "vertical" : "horizontal"}
          >
            {steps.map((label, i) => (
              <Step key={label}>
                <StepLabel className="step-label">{label}</StepLabel>
                {i == 0 && returnItems.shipment_handled_by === "customer" && (
                  <StepContent className="step-content">
                    <Button
                      variant="contained"
                      style={{ textTransform: "capitalize", marginTop: "10px" }}
                      onClick={() => setShippingDetails(true)}
                    >
                      {disabled ? "Check" : "Submit"} Shipping Details
                    </Button>
                  </StepContent>
                )}
              </Step>
            ))}
          </Stepper>

          <StyledExchange
            location={location.pathname}
            style={{
              textAlign: "left",
              marginBottom: "40px",
              justifyContent: "space-between",
              border: "none",
              paddingTop: "10px",
            }}
          >
            <div className="products-return">
              <p
                className="product-details"
                style={{
                  fontWeight: "600",
                }}
              >
                Product Details
              </p>

              <div style={{ marginTop: "20px" }}>
                <Grid
                  alignItems="center"
                  container
                  rowSpacing={{ xs: 2, md: 4 }}
                  gridTemplateColumns="repeat(12, 1fr)"
                  columnSpacing={4}
                  className="cart-products"
                >
                  {returnItems.line_items !== undefined &&
                    returnItems.line_items.length > 0 &&
                    returnItems.line_items.map((product, i) => {
                      return (
                        <Grid key={i} justifyContent="center" item xs={12}>
                          <CartProducts
                            product={product}
                            location={location.pathname}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </div>
            </div>

            <div style={{ borderRight: "2px dashed #1111111A", flex: "0.1" }} />

            <div className="price-details">
              {/* <p
                className="select pricing"
                style={{ marginBottom: "20px", marginLeft: "0px" }}
              >
                Pricing & Shipping Details
              </p> */}

              <div
                className="box address"
                style={{
                  borderBottom: !disabled ? "none" : "1px solid #11111133",
                  marginBottom: !disabled ? "0px" : "10px",
                }}
              >
                <PickupAddress
                  edit={false}
                  updatedAddress={updatedAddress}
                  disabled={disabled}
                  serviceable={true}
                />
              </div>

              {disabled && returnItems.shipment_handled_by == "customer" ? (
                <div
                  style={{
                    marginTop: "15px",
                    borderBottom: "1px solid #11111133",
                    marginBottom: "10px",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "500",
                      color: "rgb(51, 51, 51)",
                      fontSize: "18px",
                    }}
                  >
                    Ship your products back to
                  </p>
                  <p
                    style={{
                      color: "#333333B2",
                      marginTop: "5px",
                      padding: "5px 0px",
                    }}
                  >
                    {rmsConfig.return_address.name},{" "}
                    {rmsConfig.return_address.address_line_1},{" "}
                    {rmsConfig.return_address.address_line_2}{" "}
                    {rmsConfig.return_address.address_line_2 && ","}{" "}
                    {rmsConfig.return_address.city},{" "}
                    {rmsConfig.return_address.state},{" "}
                    {rmsConfig.return_address.country}, -{" "}
                    {rmsConfig.return_address.pin}
                  </p>
                </div>
              ) : (
                <div
                  style={{
                    borderBottom: "1px solid #11111133",
                    fontWeight: "500",
                    color: "rgb(51, 51, 51)",
                    fontSize: "16px",
                    paddingBottom: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <p>
                    We will let you know the warehouse address if necessary once
                    your return request is accepted
                  </p>
                </div>
              )}

              {(returnItems.return_type === "refund" ||
                returnItems.return_type === "refund_exchange" ||
                returnItems.refund_id) && (
                <PriceBox
                  infoType="Return"
                  refundSource={
                    returnItems.refund_method ||
                    returnItems.customer_preferred_refund_method
                  }
                  returnItems={returnItems}
                  setReturnItems={setReturnItems}
                  returnType={returnItems.return_type}
                />
              )}

              {(returnItems.return_type === "exchange" ||
                returnItems.return_type === "refund_exchange") &&
                (returnItems.exchange_shipment_charge > 0 ||
                  returnItems.exchange_details.length > 0) && (
                  <PriceBox
                    infoType="Exchange"
                    refundSource={
                      returnItems.refund_method ||
                      returnItems.customer_preferred_refund_method
                    }
                    returnItems={returnItems}
                    setReturnItems={setReturnItems}
                    returnType={returnItems.return_type}
                  />
                )}

              {/* 
              {returnItems.line_items && (
                <PriceBox
                  infoType="Price"
                  returnItems={returnItems}
                  refundSource={
                    (Object.keys(returnItems.refund_json).length > 0 && returnItems.refund_json.refund_method) ||
                    returnItems.refund_method ||
                    returnItems.customer_preferred_refund_method
                  }
                  setReturnItems={setReturnItems}
                />
              )} */}
              <StyledExchangeDetails>
                {returnItems.exchange_payment_details &&
                  returnItems.exchange_payment_details.length > 0 &&
                  returnItems.exchange_payment_details.map((details) => {
                    return (
                      <div>
                        <p
                          style={{
                            fontSize: "1.25rem",
                            textDecoration: "underline",
                            textUnderlineOffset: "10px",
                          }}
                        >
                          Exchange payment Details
                        </p>
                        <div className="exchange-details-header">
                          <p className="payment-status">
                            Status:{" "}
                            <span
                              style={{
                                fontWeight: "500",
                                textTransform: "uppercase",
                                padding: "2px 8px",
                                color:
                                  details.payment_status === "failed"
                                    ? "#f1416c"
                                    : details.payment_status === "completed"
                                    ? "#50cd89"
                                    : details.payment_status
                                    ? "#ffc700"
                                    : "#111",
                                background:
                                  details.payment_status === "completed"
                                    ? "#E7FCF3"
                                    : details.payment_status === "pending" ||
                                      details.payment_status === "initiated"
                                    ? "#FFF8DD"
                                    : details.payment_status === "failed"
                                    ? "#FDF5F8"
                                    : "#fff",
                              }}
                            >
                              {details.payment_status}
                            </span>
                          </p>
                          {details.payment_status === "pending" ||
                            (details.payment_status === "initiated" && (
                              <Button color="primary" variant="contained">
                                Pay now
                              </Button>
                            ))}
                        </div>
                        <p style={{ fontSize: "1.15rem", marginTop: "6px" }}>
                          Amount :{" "}
                          <span
                            style={{ fontSize: "1.25rem", fontWeight: "600" }}
                          >
                            ₹ {details.amount}
                          </span>
                        </p>
                        <p style={{ marginTop: "6px" }}>
                          Payment type:{" "}
                          <span
                            style={{
                              fontWeight: "500",
                              textTransform: "capitalize",
                            }}
                          >
                            {details.payment_type}
                          </span>
                        </p>
                        <p style={{ marginTop: "6px" }}>
                          Transaction ID:{" "}
                          <span style={{ fontWeight: "500" }}>
                            {details.transaction_id}
                          </span>
                        </p>
                        <p style={{ marginTop: "6px" }}>
                          Payment ID: {details.payment_id || "-"}
                        </p>
                        <p style={{ marginTop: "6px" }}>
                          Payment URL:{" "}
                          <a href={details.payment_url} target="_blank">
                            {details.payment_url}
                          </a>
                        </p>
                        <p style={{ marginTop: "6px" }}>
                          {" "}
                          Time of transaction:{" "}
                          <span style={{ fontWeight: "500" }}>
                            {format(
                              new Date(details.tx_time),
                              "dd MMM, yyyy hh:mm:ss aa"
                            )}
                          </span>
                        </p>
                      </div>
                    );
                  })}
              </StyledExchangeDetails>
            </div>
          </StyledExchange>

          {Object.keys(returnItems).length > 0 &&
            rmsConfig.comments_section_visible && (
              <StyledComments>
                <Comment
                  fullName={
                    returnItems.customer_first_name +
                    " " +
                    returnItems.customer_last_name
                  }
                  return_request_id={returnItems.id}
                  blurTime={blurTime}
                />
              </StyledComments>
            )}

          <Drawer
            classes={{ paper: "paper-drawer" }}
            anchor="bottom"
            open={shippingDetails}
            onClose={() => setShippingDetails(false)}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
                  height: "80px",
                  padding: "32px",
                }}
              >
                <p
                  style={{ fontSize: "1rem", fontWeight: "500", color: "#333" }}
                >
                  Enter Shipping Details
                </p>
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => setShippingDetails(false)}
                >
                  {" "}
                  <CloseIcon
                    style={{ width: "24px", height: "24px", stroke: "#333" }}
                  />{" "}
                </p>
              </div>

              <form onSubmit={(e) => submitHandler(e)}>
                <div style={{ padding: "20px" }}>
                  <label style={{ display: "block" }}>
                    <span style={{ color: "#333333B2", fontSize: "14px" }}>
                      Courier Partner Name
                    </span>
                    <input
                      type="text"
                      value={courierName}
                      disabled={disabled}
                      onChange={(e) => setCourierName(e.target.value)}
                      required
                      style={{
                        width: "100%",
                        padding: "10px",
                        marginTop: "6px",
                        borderRadius: "4px",
                        border: "1px solid #D0D4E3",
                      }}
                      placeholder="Courier partner name"
                    />
                  </label>

                  <label style={{ display: "block", margin: "20px 0px" }}>
                    <span style={{ color: "#333333B2", fontSize: "14px" }}>
                      AWB Number
                    </span>
                    <input
                      type="text"
                      value={awbNumber}
                      disabled={disabled}
                      onChange={(e) => setAwbNumber(e.target.value)}
                      required
                      style={{
                        width: "100%",
                        padding: "10px",
                        marginTop: "6px",
                        borderRadius: "4px",
                        border: "1px solid #D0D4E3",
                      }}
                      placeholder="AWB"
                    />
                  </label>

                  <label style={{ display: "block", margin: "20px 0px" }}>
                    <span style={{ color: "#333333B2", fontSize: "14px" }}>
                      Tracking Link
                    </span>
                    <input
                      type="text"
                      value={trackingLink}
                      disabled={disabled}
                      onChange={(e) => setTrackingLink(e.target.value)}
                      required
                      style={{
                        width: "100%",
                        padding: "10px",
                        marginTop: "6px",
                        borderRadius: "4px",
                        border: "1px solid #D0D4E3",
                      }}
                      placeholder="Tracking Link"
                    />
                  </label>

                  <p style={{ color: "#33333399", fontSize: "14px" }}>
                    {" "}
                    Upload Receipt Image{" "}
                  </p>

                  {preview !== null ? (
                    <div
                      style={{
                        border: "1px solid #33333366",
                        display: "flex",
                        justifyContent: disabled ? "center" : "space-between",
                        alignItems: "center",
                        minWidth: "150px",
                        width: "100%",
                        marginRight: "20px",
                        marginTop: "20px",
                        borderRadius: "4px",
                        padding: "10px",
                      }}
                    >
                      {/* <img src={preview} alt="preview" style={{width: '40px', height: '40px'}} /> */}
                      <div style={{ width: "100px" }}>
                        <ModalImage
                          small={preview}
                          large={preview}
                          alt="Preview"
                        />
                      </div>
                      {uploads !== null && uploads.size !== undefined && (
                        <p style={{ whiteSpace: "nowrap" }}>
                          {uploads.size < 1000000
                            ? Math.round((uploads.size / 1000) * 100) / 100 +
                              " KB"
                            : Math.round((uploads.size / 1000000) * 100) / 100 +
                              " MB"}
                        </p>
                      )}
                      {!disabled && (
                        <p
                          style={{ cursor: "pointer", marginTop: "4px" }}
                          onClick={() => setPreview(null)}
                        >
                          {" "}
                          <CloseIcon
                            style={{
                              width: "18px",
                              height: "18px",
                              stroke: "#333333",
                            }}
                          />{" "}
                        </p>
                      )}
                    </div>
                  ) : (
                    <Fragment>
                      <input
                        required
                        type="file"
                        id="file"
                        style={{ display: "none" }}
                        accept="video/*, image/*"
                        onChange={(e) => handleUploads(e)}
                      />
                      <label htmlFor="file">
                        <p
                          style={{
                            color: "#333",
                            padding: "20px",
                            textAlign: "center",
                            border: "1px solid #33333380",
                            margin: "20px 0px",
                            cursor: "pointer",
                          }}
                        >
                          Upload
                        </p>
                      </label>
                    </Fragment>
                  )}
                </div>

                <Button
                  type="submit"
                  disabled={disabled}
                  color="primary"
                  variant="contained"
                  style={{
                    padding: "1rem",
                    borderRadius: !merchantConfig.use_rounded_edge_buttons
                      ? "0px"
                      : "4px",
                    margin: "40px auto",
                    display: "block",
                    cursor: "pointer",
                    textTransform: "capitalize",
                  }}
                >
                  Save & Continue
                </Button>
              </form>
            </div>
          </Drawer>
          {merchantConfig.whatsapp_help &&
            merchantConfig.whatsapp_help !== null && (
              <a
                href={merchantConfig.whatsapp_help}
                target="_blank"
                rel="noreferrer"
                style={{
                  color: "#333333",
                  padding: "40px 0px",
                  textDecoration: "underline",
                }}
              >
                Need Help?
              </a>
            )}
        </div>
      )}
    </StyledApp>
  );
}

export default Tracking;
