import styled from "styled-components";

export const StyledReturnCart = styled.div`
  font-family: ${(props) => props.customFont};
  .select {
    font-size: 14px;
    color: #333;
    font-weight: 600;
    margin-left: 1rem;
    margin-bottom: 10px;
  }

  .return-request {
    padding: 15px;
    box-shadow: 0px -10px 20px 0px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    z-index: 7;
    background: #fff;
    bottom: 25px;

    .product-number {
      font-size: 14px;
    }

    .payment-form-mob {
      position: relative;
      p {
        position: absolute;
        font-size: 10px;
        top: -5px;
        left: 2px;
        letter-spacing: 1px;
      }
      fieldset {
        border: none;
      }
      .MuiSelect-select {
        padding: 10px 30px 10px 2px;
        border: none;
      }
    }
  }

  .request-button {
    border-radius: ${(props) => (props.sharpEdge ? "0px" : "4px")};
    padding: 10px;
    font-weight: 600;
    font-size: 12px;
    text-transform: capitalize;
    width: 100%;
  }

  .help {
    font-weight: 600;
    font-size: 12px;
    color: var(--color-primary);
    margin: 0px 20px 100px;
    text-decoration: underline;
  }

  .overlay {
    position: fixed;
    height: 100vh;
    bottom: 0px;
    background: #fff;
    width: 100%;
    z-index: 99;
    opacity: 0.6;
  }

  .pricing {
    margin-left: 20px !important;
  }

  @media (min-width: 800px) {
    .select {
      display: flex;
      padding-left: 11%;
      font-size: 18px;
      margin-left: 0px;
    }
    .pricing {
      margin-left: 0px !important;
    }
    .return-request {
      padding: 100px 5.5% 20px;
      top: 0;
      bottom: initial;
      display: none;
      font-size: 20px;
      max-width: 1920px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);

      .product-number {
        font-size: 20px;
      }
    }
    .request-button {
      padding: 1rem;
      margin-left: 0px;
      font-size: 14px;
      width: 100%;
      display: block;
    }
    .help {
      margin: 60px 5.5%;
    }
    .overlay {
      top: 80px;
      bottom: initial;
    }
  }
`;

export const StyledExchange = styled.div`
  padding: ${(props) =>
    props.location === "/success" ? "30px 0px 0px" : "60px 0px 0px"};
  position: relative;

  .cart-wrapper {
    .exchange-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .exchange-box {
        display: flex;
        padding: 0px 0px;
        border-radius: 4px;
        flex-direction: column;
        flex: 0.45;

        .mob-ex {
          font-size: 12px;
          margin: 0px 0px 10px;
          color: #333;
          font-weight: 500;
        }

        .upto-refund,
        .conditions {
          font-size: 12px;
        }

        .image-preview {
          width: 60px;
          height: 60px;
          object-fit: contain;
          object-position: left;
          border-radius: 4px;
        }

        .description,
        .quantity {
          font-size: 12px;
          margin: 10px 0;
          color: #333333b2;
        }

        .title,
        .price {
          font-size: 14px;
          margin: 10px 0;
          font-weight: 500;
          color: #333;
        }

        .stock {
          color: red;
          margin-left: auto;
        }
      }

      .return-icon {
        display: flex;
        align-items: center;
        flex: 0.1;
        margin: 0 10px;
      }
    }

    .return-type,
    .return-reason {
      font-size: 14px;
    }
  }

  .pickup-address-web {
    display: none;
  }

  .pricing-padding {
    padding: 0px 16px;
    font-size: 1rem;
  }

  .price-details {
    padding: ${(props) =>
      props.location === "/return-cart" ? "20px 0px 180px" : "20px 0px"};
    flex-direction: column;
    display: flex;
    width: 100%;
    color: #333;
    position: relative;
    background: #fff;

    .request-button {
      display: none;
    }

    #Returnmode {
      border-bottom: ${(props) =>
        props.refundModeError ? "1px solid red" : "none"};
    }

    .box {
      border-radius: 4px;
      padding: ${(props) =>
        props.location === "/tracking" ? "0px" : "0px 20px"};
      background: #fff;
      margin: 0px 0px;
      width: 100%;

      .title {
        font-size: 14px;
        font-weight: 600;
      }

      .MuiButtonBase-root.MuiRadio-root {
        padding: 5px 9px;
      }

      .know {
        font-size: 12px;
      }

      .refund-preference-title {
        font-size: 16px;
      }

      .radio-label,
      .refund-preference,
      .refund-note {
        font-size: 13px;
      }
    }

    .address {
      padding: ${(props) =>
        props.location === "/success" ? "0px 1.25rem" : "0px !important"};
    }
  }

  .pricing {
    font-size: 16px;
    margin: 0px 20px 20px;
  }

  .product-details {
    color: #333333;
    font-weight: 500;
    font-size: 1rem;
    margin: ${(props) =>
      props.location === "/return-cart"
        ? "0px 20px 20px"
        : props.location === "/tracking"
        ? "0px"
        : "1rem"};
  }

  .products-return {
    .accordion {
      border-radius: 0px;
      .MuiAccordionDetails-root {
        padding: 0px;
      }
    }
  }

  @media (min-width: 800px) {
    padding: ${(props) =>
      props.location === "/success" ? "30px 120px 0px" : "120px 0px 0px"};
    display: flex;

    .product-details {
      margin: ${(props) => props.location !== "/return-cart" && "1rem 0px"};
      font-size: ${(props) => props.location !== "/return-cart" && "18px"};
    }
    .products-return {
      flex: 0.5;
      padding-bottom: 25px;

      .accordion {
        margin-left: 11%;
        margin-right: 0;
      }

      .product-display {
        padding: ${(props) =>
          props.returnedProducts !== undefined &&
          props.returnedProducts.length > 0
            ? "25px 0px 0px 0px"
            : "2px 0px 0px 10%"};
      }
    }

    .pickup-address-web {
      display: block;
    }

    .pricing-padding {
      padding: 0px;
      font-size: 1.25rem;
    }

    .cart-wrapper {
      border-bottom: ${(props) =>
        props.outOfStock !== undefined &&
        props.outOfStock !== null &&
        props.returnedProducts
          .filter((a) => a.variant_id)
          .indexOf(props.outOfStock)
          ? `1px solid red`
          : "1px solid #11111133"};
      border-radius: 4px;

      .exchange-wrapper {
        .exchange-box {
          flex: 0.45;
          .mob-ex,
          .upto-refund,
          .conditions {
            font-size: 16px;
          }
          .details {
            padding: 0px 0px;
            position: relative;
            width: 100%;

            .title,
            .description,
            .quantity,
            .price {
              font-size: 16px;
            }
          }
        }

        .return-icon {
          margin: 0 20px;
        }
      }

      .return-type,
      .return-reason {
        font-size: 1rem;
      }
    }

    .pricing {
      align-items: start;
      font-size: 1.25rem;
      margin: 20px 0px 0px;
    }

    .price-details {
      padding: ${(props) =>
        props.location === "/return-cart" ? "0px 4.5% 50px" : "0px 5.5%"};
      visibility: ${(props) => (!props.webSelected ? "visible" : "hidden")};
      flex: 0.45;
      max-width: 55%;

      .request-button {
        display: block;
      }

      #Returnmode {
        border-bottom: ${(props) =>
          props.refundModeError ? "1px solid red" : "1px solid #11111133"};
      }

      .box {
        border-bottom: 1px solid #11111133;
        justify-content: space-between;
        padding: 0px 0px 20px;
        .title {
          font-size: 18px;
          margin: 0px;
        }
        .MuiButtonBase-root.MuiRadio-root {
          padding: 9px;
        }
        .refund-preference-title {
          font-size: 18px;
        }
        .know,
        .radio-label,
        .refund-preference,
        .refund-note {
          font-size: 16px;
        }
      }

      .address {
        padding: 10px 0px !important;
        display: block;
      }
    }
  }
`;

export const StyledPaymentDiv = styled.div`
  background: #fff;
  padding: 20px;

  .payment-title {
    font-size: 14px;
    color: #333;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .subtotal-statement {
    font-size: 12px;
  }

  @media (min-width: 800px) {
    padding: 10px 0px;
    .payment-title {
      font-size: 1.15rem;
      margin-top: 10px;
      margin-bottom: 14px;
    }
    .subtotal-statement {
      font-size: 16px;
    }
  }
`;

export const StyledPaymentMode = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 10px 0px;
  padding: ${(props) => (props.bonus ? "8px 10px" : "10px")};
  border: ${(props) =>
    props.bonus ? "1px solid #16AC37" : `1px solid  ${props.primary_color}33`};
  background: ${(props) =>
    props.selected && props.bonus
      ? "rgb(22, 172, 55)"
      : props.selected && props.charges
      ? "rgb(241,65,108)"
      : props.selected
      ? props.primary_color
      : props.bonus
      ? "#e7fcf3"
      : "transparent"};
  color: ${(props) => (props.selected ? "#fff" : "")};

  :hover {
    background: ${(props) =>
      props.selected && props.bonus
        ? "rgb(22, 172, 55)"
        : props.selected && props.charges
        ? "rgb(241,65,108)"
        : props.selected
        ? props.primary_color
        : props.bonus
        ? "rgb(22, 172, 55, 0.25)"
        : `${props.primary_color}11`};
  }

  .handling-charges {
    background: #fdf5f8;
    color: #f1416c;
    font-weight: 600;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 4px;
    width: fit-content;
    margin-left: 10px;
    text-wrap: nowrap;
    letter-spacing: 1px;
  }

  @media (max-width: 800px) {
    font-size: 14px;
  }
`;
